<template>
  <v-container
    id="data-tables"
    class="fill-height align-start"
    fluid
    tag="section"
  >
    <v-card
      class="cardItem"
      style="width: 100%"
    >
      <v-toolbar
        color="primary"
        dark
        dense
      >
        <v-toolbar-title @click="goback">
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t('return') }}
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loading"
          dense
          fixed-header
          height="calc(100vh - 72px)"
        >
          <template
            v-for="(s, idx) in sectionHeaders"
            #[`item.${s.name}`]="{ item }"
          >
            <v-checkbox
              :key="idx"
              :disabled="loading"
              :input-value="item[s.name]"
              @click="updateDishDiscount(item, s)"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import Section from '../../../model/Section'
  import DiscountRules from '../../../model/discount/DiscountRules'
  import { getAllDishes, getDishesDiscountRuleList, updateDishesDiscountRule } from '@/common/Utlis/api'
  import i18n from '../../../i18n'

  export default {
    data: function () {
      return ({
        model: Section,
        displayData: [],
        loading: true,
        fixedHeader: [
          {
            text: i18n.t('code'),
            value: 'code',
            align: 'center',
          },
          {
            text: i18n.t('name'),
            value: 'dishName',
          },
        ],
        sectionHeaders: [],
        dishesRuleList: [],
      })
    },
    computed: {
      headers () {
        return this.fixedHeader.concat(this.sectionHeaders)
      },
      tableData () {
        const tableData = []
        this.displayData.forEach(d => {
          this.sectionHeaders.forEach(s => {
            let haveExists = false
            haveExists = this.dishesRuleList.some((newItem, i) => {
              if ((d.dishesId === newItem.dishesId) && (s.id === parseInt(newItem.discountRuleId))) {
                return true
              }
            })
            d[s.name] = haveExists
          })
          tableData.push(d)
        })
        return this.displayData
      },
    },
    mounted () {
      this.loadData()
    },
    methods: {
      goback () {
        this.$router.history.go(-1)
      },
      async updateDishDiscount (item, sItem) {
        this.loading = false
        item[sItem.name] = !item[sItem.name]
        const dishDsctItem = {
          dishesId: item.dishesId,
          discountRuleId: sItem.id,
        }
        if (item[sItem.name]) {
          this.dishesRuleList.push(dishDsctItem)
        } else {
          const index = this.dishesRuleList.findIndex((ele) => {
            return (ele.dishesId === item.dishesId) && (ele.discountRuleId === sItem.id)
          })
          this.dishesRuleList.splice(index, 1)
        }
        await updateDishesDiscountRule(this.dishesRuleList)
      },
      async loadData () {
        this.sectionHeaders = (await DiscountRules.getList()).map(a => {
          a.text = a.name
          a.value = a.name
          return a
        })
        await getDishesDiscountRuleList().then(res => {
          this.dishesRuleList = res.content
        })
        await getAllDishes().then(res => {
          this.loading = false
          this.displayData = res.content.map(function (item) {
            const dishRulesItem = {
              dishName: '',
              dishesId: '',
              code: '',
            }
            dishRulesItem.dishesId = item.dishId
            dishRulesItem.dishName = item.dishName
            dishRulesItem.code = item.code
            return dishRulesItem
          })
        })
      },
    },
  }
</script>

<style scoped>
.cardItem {
  margin-top: 0px;
}
</style>
